<template>
  <div class="widgetContainer cardDetails bg-white">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <span class="icon-Arrow-big-left cancel" @click="drawerBack" />
        <p class="title">
          {{ $t('ATM_list') }}
        </p>
      </div>
    </div>
    
    <div class="widgetContainer__body">
      <div v-if="!havePermission"
           class="noData">
        <p>{{ $t('location_permission') }}</p>
      </div>
      <div class="widgetContainer--scrollable"
           v-infinite-scroll="load"
           :infinite-scroll-disabled="disabledLoadMore"
           :infinite-scroll-immediate="false"
           v-else-if="atms.total !== 0">
        <ul class="atm-list">
          <li
            class="atm-list__item"
            v-for="atm in atms.data"
            :key="atm.id">
            <div class="initialsInfoContact initialCaps">
              <span>{{ atm.name.charAt(0) }}</span>
            </div>
            <div class="details">
              <p class="name initialCaps">
                {{ atm.name }}
              </p>
              <p class="description initialCaps">
                {{ atm.description }}
              </p>
            </div>
          </li>
        </ul>
        <div class="infinite-loading"
             v-if="loading && page !== 1">
          <p>Loading...</p>
        </div>
      </div>
      <div class="noData"
           v-else>
        <p>
          {{ $t('no_ATM') }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'FindATM',
  data() {
    return {
      lat: null,
      long: null,
      page: 1,
      limit: 100,
      loading: false,
      havePermission: false,
      atms: {
        data: [],
        total: 0
      }
    }
  },
  created() {
    if (navigator.geolocation) {
      const loader = this.showLoader();
      navigator.geolocation.getCurrentPosition((position) => {
        if(position && position.coords && position.coords.latitude && position.coords.longitude) {
          this.lat = position.coords.latitude;
          this.long = position.coords.longitude;
          this.havePermission = true;
          loader.close();
          this.fetchATMs();
        }
      }, (e) => {
        console.log(e)
        this.havePermission = false;
        loader.close();
      });
    } else { 
      this.havePermission = false;
      let m = 'Geolocation is not supported by this browser.';
      console.log(m);
    }
  },
  computed: {
    ...mapGetters('card', ['getSelectedCard']),
    disabledLoadMore() {
      return (
        this.loading === true ||
        this.atms.total <= this.page * this.limit
      );
    }
  },
  methods: {
    ...mapActions('card',['findNearATM']),
    fetchATMs() {
      const loader = this.showLoader();
      this.findNearATM({
        cardId: this.getSelectedCard.id,
        lat: this.lat,
        long: this.long,
        // lat: '41.972424',
        // long: '-87.660225',
        limit: this.limit,
        page: this.page
      }).then((atms) => {
        if(this.page === 1) {
          this.atms = atms;
        } else {
          this.atms.data.push(...atms.data);
        }
      }).finally(() => {
        loader.close();
      })
    },
    load() {
      if (
        this.loading === false &&
        this.atms.total > this.page * this.limit
      ) {
        this.page++;
        this.fetchATMs();
      }
    },
  }
}
</script>
<style lang="scss">
.atm-list {
    &__item {
        display: flex;
        column-gap: 10px;
        margin-bottom: 15px;
        border-bottom: 1px solid #c5c5c5;
        padding-bottom: 15px;
        .initialsInfoContact {
        width: 40px;
        min-width: 40px;
        height: 40px;
        }
        .details {
        .description {
            font-size: 12px;
            color: gray;
            margin-top: 5px;
        }
        }
    }
    .details {
      .description {
        font-size: 12px;
        color: gray;
        margin-top: 5px;
      }
    }
}
</style>